
























import {Vue, Component, Watch} from "vue-property-decorator";
import FeedbackShopType from "./FeedbackShop";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import FeedbackShopSearch from "@/views/Shop/FeedbackShop/components/Search/index.vue";
import FeedbackSelectShop from "@/views/Shop/FeedbackShop/components/Select/index.vue";
import {FeedbackShopStore} from "@/views/Shop/FeedbackShop/Store";
import ListItem from "@/components/ShopComponent/OrderItem.vue"
import {getFindExpress} from "@/views/Shop/FeedbackShop/Server";

@Component({name: "FeedbackShop",components:{ HeadTop,PullDownUpList,ListBottom,FeedbackSelectShop,FeedbackShopSearch,ListItem }})
export default class FeedbackShop extends ZoomPage implements FeedbackShopType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []
    id = ""

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { id = "" } = this.$route.query
        if ( id != this.id ){
            this.id = id as string;
            this.UpDownBool = true
            let upData = FeedbackShopStore.getFeedbackShopUpData
            upData.userId = id as string
            FeedbackShopStore.SetFeedbackShopUpData(upData)
            this.handleUpData(true)
        }
    }

    handleGetSelectSearch(){
        this.UpDownBool = true
        this.handleUpData(true)
    }

    handlePullDown(){
        this.PullDown = true
        let upData = FeedbackShopStore.getFeedbackShopUpData;
        upData.pageNo = 1;
        FeedbackShopStore.SetFeedbackShopUpData(upData)
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        this.UpDownBool = true
        let upData = FeedbackShopStore.getFeedbackShopUpData;
        upData.pageNo += 1;
        FeedbackShopStore.SetFeedbackShopUpData(upData)
        this.handleUpData()
    }

    handleUpData(bool=false){
        getFindExpress().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FeedbackShopStore.getFeedbackShopUpData.pageSize )this.StopUp = true;
            if ( bool )
                FeedbackShopStore.SetFeedbackShopList( res );
            else
                FeedbackShopStore.SetFeedbackShopList( FeedbackShopStore.getFeedbackShopList.concat(res) );
        })
    }

    get getList(){
        return FeedbackShopStore.getFeedbackShopList
    }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
