

















































import {Vue, Component, Emit} from "vue-property-decorator";
import FeedbackSelectOrderType from "./indexType";
import PullItem from "@/components/ShareComponent/PullSelItem.vue"
import {RoomType} from "@/Type/index";
import {OrderStatus, PlatformList, StoreType} from '../../Model';
import {FilterPullItem} from "@/util/filter";
import {UserStore} from "@/store/modules/User";
import {getToList} from "@/server";
import {FeedbackShopStore} from "@/views/Shop/FeedbackShop/Store";

@Component({name: "FeedbackSelectShop", components: {PullItem,}})
export default class FeedbackSelectShop extends Vue implements FeedbackSelectOrderType {
    SelShowList = [false, false, false, false,]
    ZoomList: any[] = [];
    ZoomIndex = 0
    TypeList: any[] = [];
    TypeIndex = 0;
    StatusList: any[] = [];
    StatusIndex = 0
    ToList: Array<RoomType> = []
    ToIndex = 0;

    mounted(){
        this.ZoomList = PlatformList
        this.StatusList = OrderStatus
        this.TypeList = StoreType
        this.initData()
    }

    initData(){
        let { state = "" } = this.$route.query
        if ( state ){
            let upData = FeedbackShopStore.getFeedbackShopUpData;
            upData.storeType = Number(state) || 0
            this.TypeIndex = Number(state) || 0
            FeedbackShopStore.SetFeedbackShopUpData(upData)
            this.pullSearch()
        }
        if( UserStore.GetRoomList.length ){
            this.ToList = FilterPullItem(UserStore.GetRoomList,"dicCode")
        }else{
            getToList().then(res=>{
                this.ToList = res
            })
        }
    }

    handlePullItem(data: any) {
        let upData = FeedbackShopStore.getFeedbackShopUpData;
        upData.pageNo = 1
        switch (Number(data.state)) {
            case 0:
                upData.deliveryType = data.data.state
                this.ZoomIndex = data.index
                break;
            case 1:
                this.TypeIndex = data.index
                upData.storeType = data.data.state
                break;
            case 2:
                upData.status = data.data.state
                this.StatusIndex = data.index
                break;
            case 3:
                this.ToIndex = data.index
                upData.buildNumberName = data.data.title
                break;
        }
        FeedbackShopStore.SetFeedbackShopUpData(upData)
        this.pullSearch()
    }

    handleClickPull(idx: number) {
        let List: boolean[] = []
        if ( idx == 4 ){
            let upData = FeedbackShopStore.getFeedbackShopUpData;
            FeedbackShopStore.SetFeedbackShopUpData({pageNo:1,pageSize:15,userId:upData.userId})
            FeedbackShopStore.SetFeedbackShopClear(true)
            this.pullSearch()
        }
        this.SelShowList.forEach((item,index)=>{
            if ( idx == 4 ){
                List.push( false )
                return;
            }
            if ( idx == index ) {
                List.push( !item )
            }else{
                List.push( false )
            }
        })
        this.SelShowList = List
    }

    @Emit("pullSearch")
    pullSearch(){
        return
    }
}
