










import {Vue, Component, Watch, Emit, Ref} from "vue-property-decorator";
import AddressBookSearchType from "./indexType";
import Search from "@/components/ShareComponent/Search.vue";
import {FeedbackShopStore} from "@/views/Shop/FeedbackShop/Store";


@Component({name: "FeedbackShopSearch",components:{ Search }})
export default class FeedbackShopSearch extends Vue implements AddressBookSearchType{
    @Ref("searchFriendInput")
    InputObj:any

    show = false
    value = ""

    handleChangeShow(){
        this.show = true
        let time = setTimeout(()=>{
            this.InputObj.focus()
            clearTimeout(time)
        })
    }

    handleBlur(){
        if ( this.value.length < 1 ) this.show = false;
    }

    handleClickDown(){
        if ( !(this.show && this.value.length) )return;
        let upData = FeedbackShopStore.getFeedbackShopUpData;
        upData.orderId = this.value
        FeedbackShopStore.SetFeedbackShopUpData(upData)
        this.pullSearch()
    }

    get getClear(){ return FeedbackShopStore.getFeedbackShopClear }
    @Watch("getClear")
    changeClear(newVal:boolean){
        if ( newVal ){
            this.value = ""
            this.handleBlur()
        }
    }

    @Emit("pullSearch")
    pullSearch(){
        return
    }
}
